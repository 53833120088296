import { loadAccountInformation } from '../../shared/shim';

import { deleteColour } from './deleteColour';
import { loadAccountActivities } from './loadAccountActivities';
import { loadAccountActivity } from './loadAccountActivity';
import { loadActivityCategories } from './loadActivityCategories';
import { loadValidationRuleCategories } from './loadValidationRuleCategories';
import { loadActivityGroups } from './loadActivityGroups';
import { loadColours } from './loadColours';
import { loadCoordinates } from './loadCoordinates';
import { loadDefaultPermissions } from './loadDefaultPermissions';
import { loadFilterOperators } from './loadFilterOperators';
import { loadHeaderCategories } from './loadHeaderCategories';
import { loadHeaders } from './loadHeaders';
import { loadHeaderTypes } from './loadHeaderTypes';
import { loadList } from './loadList';
import { loadListCategories } from './loadListCategories';
import { loadLists } from './loadLists';
import { loadSampleDispatchHeaders } from './loadSampleDispatchHeaders';
import { loadSampleResultsRankedUnits } from './loadSampleResultsRankedUnits';
import { loadSampleWorkflows } from './loadSampleWorkflows';
import { loadSystemFilters } from './loadSystemFilters';
import { loadTableCategories } from './loadTableCategories';
import { loadTables } from './loadTables';
import { loadTableViews } from './loadTableViews';
import { saveColour } from './saveColour';
import { switchSubscription } from './switchSubscription';
import { getExportTemplates } from './getExportTemplates';
import { getCustomExports } from './getCustomExports';
import { loadValidationRulesShim } from './loadValidationRulesShim';

export const subscriptionShim = {
    loadAccountActivities,
    switchSubscription,
    loadAccountInformation,
    loadTableViews,
    loadTables,
    loadTableCategories,
    loadList,
    loadLists,
    loadListCategories,
    loadHeaderCategories,
    loadHeaders,
    loadHeaderTypes,
    loadSystemFilters,
    loadFilterOperators,
    loadDefaultPermissions,
    loadCoordinates,
    loadActivityGroups,
    loadActivityCategories,
    loadValidationRuleCategories,
    loadColours,
    loadSampleResultsRankedUnits,
    saveColour,
    deleteColour,
    loadSampleDispatchHeaders,
    loadSampleWorkflows,
    loadAccountActivity,
    getExportTemplates,
    getCustomExports,
    loadValidationRulesShim,
};
